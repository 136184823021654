<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="客户名称">
              <a-select
                showSearch
                allowClear
                placeholder="请选择客户名称"
                style="width: 100%"
                v-model="queryParam.customer_id"
              >
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用名称">
              <a-select
                showSearch
                allowClear
                placeholder="请选择费用名称"
                style="width: 100%"
                v-model="queryParam.factory_id"
              >
                <a-select-option v-for="op in feeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="车型">
              <a-select
                showSearch
                allowClear
                placeholder="请选择车型"
                style="width: 100%"
                v-model="queryParam.factory_id"
              >
                <a-select-option v-for="op in vehicleTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete('all')">删除</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal :width="760" :visible="formVisible"
             :title="modalFlag === 'create' ? '创建出库费用规则' : '编辑出库费用规则'"
             :confirmLoading="confirmLoading"
             @ok="handleSubmit" @cancel="formVisible = false">
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="客户名称">
              <a-select
                showSearch
                allowClear
                placeholder="请选择客户名称"
                ref="customer"
                style="width: 100%"
                v-model="form.customer_id"
              >
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="费用名称">
              <a-select
                showSearch
                allowClear
                placeholder="请选择费用名称"
                ref="fee"
                style="width: 100%"
                v-model="form.fee_id"
              >
                <a-select-option v-for="op in feeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="车型">
              <a-select
                showSearch
                allowClear
                placeholder="请选择车型"
                ref="vehicle"
                style="width: 100%"
                v-model="form.vehicle_type_id"
              >
                <a-select-option v-for="op in vehicleTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="计费单位">
              <a-select
                showSearch
                allowClear
                placeholder="请选择计费单位"
                style="width: 100%"
                v-model="form.charge_unit"
              >
                <a-select-option v-for="op in chargeUnitMap" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="计费周期">
              <a-input-number v-model="form.charge_period" class="full-width"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="免租天数">
              <a-input-number v-model="form.free_day_count" class="full-width"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="计费金额" prop="day_amount">
              <a-input v-model="form.day_amount" />
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <a-form-model-item label="计费时间段">
              <a-row>
                <a-col :span="3"><a-input-number v-model="dayFee.to" /></a-col>
                <a-col :span="2" :offset="2"><div class="center">--</div></a-col>
                <a-col :span="3"><a-input-number v-model="dayFee.from" /></a-col>
                <a-col :span="3" :offset="2"><div class="right">金额: </div></a-col>
                <a-col :span="4"><a-input v-model="dayFee.amount" /></a-col>
                <a-col :span="4"><div class="center"><a-button @click="handleFeeRangeAdd">添加</a-button></div></a-col>
              </a-row>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-tag :key="index" v-for="(t, index) in form.day_fee_info_list"
               :closable="true" @close="() => handleClose(t, index)">
          {{ t.from + '时---' + t.to + '时：' + t.amount + ' 元' }}
        </a-tag>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getWmsDataPage, getWmsDataOption, createWmsDataInfo, updateWmsDataInfo, deleteWmsDataInfo } from '@/api/wms'
import { getCommonOptions } from '@/api/common'
import { validateMoney } from '@/utils/validate'
export default {
  components: {
    STable
  },
   data() {
    return {
      queryParam: {},
      customerOps: [],
      vehicleTypeOps: [],
      feeOps: [],
      chargeUnitMap: {
        1: { value: 1, name: '件数' },
        2: { value: 2, name: '毛重' },
        3: { value: 3, name: '体积' },
        4: { value: 4, name: '托' }
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name'
        },
        {
          title: '费用名称',
          dataIndex: 'fee_name'
        },
        {
          title: '车型',
          dataIndex: 'vehicle_type'
        },
        {
          title: '计费单位',
          dataIndex: 'charge_unit'
        },
        {
          title: '计费周期',
          dataIndex: 'charge_period'
        },
        {
          title: '免租天数',
          dataIndex: 'free_day_count'
        },
        {
          title: '计费金额',
          dataIndex: 'day_amount'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      loadData: parameter => {
        return getWmsDataPage('out_charge_rule', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      dayFee: {
        to: null,
        from: null,
        amount: null
      },
      form: {
        customer_id: null,
        fee_id: null,
        vehicle_type_id: null,
        charge_unit: '',
        charge_period: null,
        free_day_count: null,
        day_amount: null,
        day_fee_info_list: []
      },
      rules: {
        day_amount: {
          validator: this.checkAmount, trigger: 'blur'
        }
      },
      formVisible: false,
      confirmLoading: false,
      currentData: null,
      modalFlag: 'create'
    }
  },
  created() {
    // getCommonOptions('customer').then(v => {
    //   this.customerOps = v
    // })
    getCommonOptions('fee').then(v => {
      this.feeOps = v
    })
    getWmsDataOption('vehicle_type').then(f => {
      this.vehicleTypeOps = f
    })
  },
  methods: {
    checkAmount(rule, value, callback) {
      if (!value || validateMoney(value)) {
        callback()
      } else {
        callback(new Error('请输入合法的金额'))
      }
    },
    handleFeeRangeAdd() {
      this.form.day_fee_info_list.push(this.dayFee)
      this.dayFee = {
        to: null,
        from: null,
        amount: null
      }
    },
    handleClose(t, index) {
      this.form.day_fee_info_list.splice(index, 1)
    },
    handleCreate() {
      this.modalFlag = 'create'
      this.form = {
        customer_id: null,
        fee_id: null,
        vehicle_type_id: null,
        charge_unit: '',
        charge_period: null,
        free_day_count: null,
        day_amount: null,
        day_fee_info_list: []
      }
      this.formVisible = true
      this.$nextTick(_ => {
        this.$refs.form.resetFields();
        this.$refs.form.clearValidate();
      })
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.formVisible = true
      this.form = record
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let func = createWmsDataInfo
          if (this.modalFlag === 'update') {
            func = updateWmsDataInfo
          }
          const data = { ...this.form,
            customer_name: this.$refs['customer'].$el.innerText,
            fee_name: this.$refs['fee'].$el.innerText,
            vehicle_type: this.$refs['vehicle'].$el.innerText
          }
          func('out_charge_rule', data).then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
              })
              if (this.modalFlag === 'create') {
                this.$refs.table.refresh(true)
              }
            }).catch(_ => {
              this.formVisible = false
              this.confirmLoading = false
            })
        } else {
          return false;
        }
      });
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteWmsDataInfo('out_charge_rule', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>

<style scoped>
  .right {
    padding-right: 5px;
    text-align: right
  }
  .center {
    text-align: center
  }
</style>
